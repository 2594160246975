import { Status } from "./status.model";

export class JobApplicationStatus {
    constructor(
        public createdDate: string = "",
        public createdBy: string = "",
        public id: string = "",
        public jobApplication: string = "",
        public status: Status = new Status(),
        public note: string = "",
        public statusTimestamp: string = "1900-01-01",
        public statusName: string = ""
    ){}
}