import { AccessTokenPayload } from "../models/auth/access-token-payload.model";
import { BehaviorSubject, Observable } from 'rxjs';
import $ from 'jquery';
import Swal, { SweetAlertIcon } from "sweetalert2";

export class UtilService {
    private static instance: UtilService;
    public readonly transitionSubject = new BehaviorSubject<boolean>(false);

    private constructor() { }

    public static GetInstance(): UtilService {
        if (!UtilService.instance) {
            UtilService.instance = new UtilService();
        }

        return UtilService.instance;
    }

    OnTransition(): Observable<boolean> {
        return this.transitionSubject.asObservable();
    }

    ToggleFilter(id: string, callback?: () => void) {
        this.Toggle(id, callback);
    }

    HideFilter(id: string) {
        if (!$(id).is(":visible")) {
            this.ToggleFilter(id)
        }
    }

    Toggle(show_Id: string, callback?: () => void) {
        this.transitionSubject.next(true);

        $(show_Id).slideToggle({
            complete: () => {
                this.transitionSubject.next(false);

                callback?.();
            }
        })
    }

    UpDown(hide_Id: string, show_Id: string, callback?: () => void) {
        this.transitionSubject.next(true);

        $(hide_Id).slideToggle({
            complete: () => {
                $(show_Id).slideToggle()
                this.transitionSubject.next(false);

                callback?.();
            }
        })
    }

    ShowLeft(hide_Id: string, show_Id: string, duration: number, colsToRemove: string, colToAdd: string, type: string, callback?: () => void) {
        const { width } = this.ScreenSize();
        const $hideElement = $(hide_Id);
        const $showElement = $(show_Id);

        if ($hideElement.hasClass(colsToRemove)) {
            this.transitionSubject.next(true);

            $hideElement.css('transition', `${duration / 1000}s ${type}`);
            $hideElement.removeClass(colsToRemove);
            $hideElement.addClass(colToAdd);

            const transitionComplete = () => {
                this.transitionSubject.next(false);

                callback?.();
            };

            if (width >= 768) {
                setTimeout(() => {
                    $showElement.slideToggle(transitionComplete);
                }, duration + 100);
            } else {
                $showElement.slideToggle();
                const offset = $showElement?.offset();

                if (offset) {
                    $('html, body').animate({
                        scrollTop: offset.top
                    }, 1300, transitionComplete);
                } else {
                    transitionComplete();
                }
            }
        }
    }

    HideLeft(hide_Id: string, show_Id: string, duration: number, colsToRemove: string, colToAdd: string, type: string, callback?: () => void) {
        const { width } = this.ScreenSize();
        const $hideElement = $(hide_Id);
        const $showElement = $(show_Id);

        this.transitionSubject.next(true);

        $hideElement.slideToggle({
            complete: () => {
                $showElement.css('transition', `${duration / 1000}s ${type}`);
                $showElement.removeClass(colsToRemove);
                $showElement.addClass(colToAdd);

                if (width < 768) {
                    $showElement.slideToggle();
                    const offset = $showElement?.offset();

                    if (offset) {
                        $('html, body').animate({
                            scrollTop: offset.top
                        }, 1300, () => {
                            this.transitionSubject.next(false);

                            callback?.();
                        });
                    } else {
                        callback?.();
                    }
                } else {
                    this.transitionSubject.next(false);

                    callback?.();
                }
            }
        });
    }

    ScreenSize(): { height: number, width: number } {
        return { height: window.innerHeight, width: window.innerWidth };
    }

    public static DecodeJWT(token: string): AccessTokenPayload {
        const payloadBase64 = token.split('.')[1];
        const decodedPayload = atob(payloadBase64);

        return JSON.parse(decodedPayload);
    }

    public static Alert(title: string, icon: SweetAlertIcon, html: string, width?: string, then?: () => Promise<void> | void) {
        Swal.fire({
            title,
            icon,
            html,
            ...(width && { width })
        }).then(async () => {
            if (then) {
                await then();
            }
        });
    }
}

