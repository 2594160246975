import { Location } from 'react-router-dom';

const regexUUID = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

const GetSelected = (location: Location<any>): string => {
    const path = location.pathname;

    if (path.includes('/private/profile')) {
        return '/private/profile';
    }

    if (path === '/private/dashboard') {
        return '/private/dashboard';
    }

    if (path === '/private/job') {
        return '/private/job';
    }

    if (path === '/private/job-applications') {
        return '/private/job-applications';
    }

    if (path.includes('/private/job/')) {
        return `/private/job`;
    }

    if (path.includes('/private/job-applications/')) {
        let sliced = path.split("/");

        const id = sliced[sliced.length - 1];

        if (regexUUID.test(id) && location.state) {
            let from = location.state.from ?? 1;

            if (from === 1) {
                return '/private/job-applications';
            }

            if (from === 2) {
                return `/private/job`;
            }
        } else if (regexUUID.test(id)) {
            return `/private/job`;
        }

        return '/private/job-applications';
    }

    return '';
};

const GetBreadCrumb = (location: Location<any>): { label: string; path: string }[] => {
    const path = location.pathname;

    if (path.includes('/private/profile')) {
        if (path.includes("update-password")) {
            return [
                { label: 'Profile', path: '/private/profile' },
                { label: 'Update Password', path: '/private/profile/update-password' },
            ];
        }

        return [
            { label: 'Profile', path: '/private/profile' },
        ];
    }

    if (path === '/private/dashboard') {
        return [
            { label: 'Dashboard', path: '/private/dashboard' },
        ];
    }

    if (path === '/private/job') {
        return [
            { label: 'Jobs', path: '/private/job' },
        ];
    }

    if (path.includes('/private/job/')) {
        let sliced = path.split("/")

        
        if(!path.includes("preview") && !path.includes("job-applications")){
            const id = sliced[sliced.length - 1];

            if(regexUUID.test(id)){
                return [
                    { label: 'Jobs', path: '/private/job' },
                    { label: `Job Form / ${id}`, path: `/private/job/${id}` },
                ];
            }

            return [
                { label: 'Jobs', path: '/private/job' },
                { label: 'Job Form', path: `/private/job/create` },
            ];
        }

        const id = sliced[sliced.length - 2];

        if(path.includes("preview")){
            return [
                { label: 'Jobs', path: '/private/job' },
                { label: `Preview`, path: `/private/job/${id}/preview` },
            ];
        }

        return [
            { label: 'Jobs', path: '/private/job' },
            { label: 'Job Applications', path: `/private/job/${id}/job-applications` },
        ];
    }

    if (path === '/private/job-applications') {
        return [
            { label: 'Job Applications', path: '/private/job-applications' },
        ];
    }

    if (path.includes('/private/job-applications/')) {
        let sliced = path.split("/");

        const id = sliced[sliced.length - 1];

        if (regexUUID.test(id) && location.state) {
            let from = location.state.from ?? 1;

            if (from === 1) {
                return [
                    { label: 'Job Applications', path: '/private/job-applications' },
                    { label: id, path: `/private/job-applications/${id}` },
                ];
            }

            if (from === 2) {
                let jobId = location.state.jobId ?? undefined;

                return [
                    { label: 'Jobs', path: '/private/job' },
                    { label: 'Job Applications', path: `/private/job/${jobId}/job-applications` },
                    { label: id, path: `/private/job-applications/${id}` },
                ];
            }
        } else if (regexUUID.test(id)) {
            return [
                { label: 'Job Applications', path: '/private/job-applications' },
                { label: id, path: `/private/job-applications/${id}` },
            ];
        }

        return [
            { label: 'Job Applications', path: '/private/job-applications' },
        ];
    }

    return [{ label: 'Home', path: '/private/dashboard' }];
};

function FormatPhone(phoneNumber: string) {
    if (phoneNumber.length === 11) {
        const countryCode = `+${phoneNumber.slice(0, 3)}`;
        const mainNumber = `${phoneNumber.slice(3, 7)} ${phoneNumber.slice(7, 11)}`;

        return `${countryCode} ${mainNumber}`;
    }

    return phoneNumber;
}

export { GetSelected, GetBreadCrumb, FormatPhone }