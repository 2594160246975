import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { Form } from 'antd';

interface Props {
    title: string;
    placeholder: string;
    id: string;
    inputClass?: string;
    value: string;
    name: string;
    simple?: boolean;
    simplestate?: boolean;
    OnChange?: (name: string, value: string) => void;
}

const Quill: React.FC<Props> = ({ title, placeholder, id, inputClass, value, simple = false, simplestate = false, name, OnChange }) => {
    return (
        <div className={`${inputClass}`}>
            {
                (!simple || !simplestate) && (
                    <span className="text-left block mb-2 text-sm font-medium text-gray-600">{title}</span>
                )
            }

            <Form className='rounded-xl'>
                <Form.Item className='rounded-xl'>
                    <ReactQuill
                        theme="snow"
                        value={value}
                        onChange={(value) => OnChange?.(name, value)}
                        className='rounded-xl'
                        placeholder={!simple || !simplestate ? placeholder: title}
                        id={id}
                        style={{
                            height: '300px',
                        }}
                    />
                </Form.Item>
            </Form>
        </div>
    );
};

export default Quill;
