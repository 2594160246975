import { Select } from 'antd';
import React from 'react';
import './Select.css'
const { Option } = Select;

interface OptionType {
    label: string;
    value: string;
}

interface Props {
    title: string;
    placeholder: string;
    options: OptionType[];
    name: string;
    selectClass?: string;
    value: string;
    simple?: boolean;
    simplestate?: boolean;
    onChange: (name: string, value: string) => void;
    disabled?: boolean;
}

const CustomSelect: React.FC<Props> = ({ title, placeholder, options, name, selectClass, value, simple = false, simplestate = false, onChange, disabled = false }) => {
    return (
        <div className={`${selectClass}`}>
            {
                (!simple || !simplestate) && (
                    <span className="text-left block mb-2 text-sm font-medium text-gray-600">
                        {title}
                    </span>
                )
            }

            <Select
                className={`w-full h-[34px] disabled:opacity-50 disabled:bg-gray-100 disabled:text-gray-400 ${disabled ? 'opacity-100 bg-gray-white text-black backgrau' : ''}`}
                placeholder={!simple || !simplestate ? placeholder: title}
                value={value || undefined}
                onChange={(value) => onChange(name, value)}
                allowClear={false}
                disabled={disabled}
                getPopupContainer={(trigger) => trigger.parentNode}
                style={{
                    textAlign: 'left',
                    background: "white",
                    backgroundColor: disabled ? '#f3f4f6' : 'white',
                    color: disabled ? '#9ca3af' : 'black',
                }}
            >
                {options.map((option) => (
                    <Option key={option.value} value={option.value}>
                        {option.label}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

export default CustomSelect;
