import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { Job } from '../models/jobs/job.model';

interface JobState {
    job: Job | undefined;
    setJob: (job: Job) => void;
    getJob: () => Job | undefined;
    resetJob: () => void;
}

const useJobStore = create<JobState>()(
    devtools(
        persist(
            (set, get) => ({
                job: undefined,
                setJob: (job) => set({ job }),
                getJob: () => get().job,
                resetJob: () => set({ job: undefined }),
            }),
            {
                name: 'job-store', 
                storage: createJSONStorage(() => sessionStorage)
            }
        )
    )
);

export { useJobStore };
