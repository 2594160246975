import { Sort } from "./sort.model";

export class Pageable {
    constructor(
        public offset: number = 0,
        public sort: Sort = new Sort(),
        public paged: boolean = false,
        public pageNumber: number = 0,
        public pageSize: number = 0,
        public unpaged: boolean = false,
    ) {}
}