import { Outlet, useLocation, useNavigate, Link } from 'react-router-dom';
import { UtilService } from '../../core/services/util.service';
import { useAuthStore } from '../../core/store/auth.store';
import { Dropdown, Spinner } from '../../core/components';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import { GetBreadCrumb, GetSelected } from './util';
import React, { useEffect, useState } from 'react';
import type { MenuProps } from 'antd';
import './Layout.css';
import {
  DesktopOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
  ProfileOutlined,
  UserOutlined
} from '@ant-design/icons';

const { Header, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

const GetItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  onClick?: () => void,
  children?: MenuItem[],
): MenuItem => ({
  label,
  key,
  icon,
  onClick,
  children,
});

const PrivateLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth, resetAuth } = useAuthStore();
  const [transition, setTransition] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(80);

  const utils = UtilService.GetInstance();

  const lgBreakpoint = 992;

  const breadcrumbs = GetBreadCrumb(location);

  const { token: { colorBgContainer } } = theme.useToken();

  const menuItems: MenuItem[] = [
    GetItem('Dashboard', '/private/dashboard', <PieChartOutlined />, () => navigate('/private/dashboard')),
    GetItem('Jobs', '/private/job', <DesktopOutlined />, () => navigate('/private/job')),
    GetItem('Job Applications', '/private/job-applications', <DesktopOutlined />, () => navigate('/private/job-applications')),
  ];

  const SidebarWidth = () => {
    return window.innerWidth <= lgBreakpoint ? 0 : 80;
  };

  useEffect(() => {
    setSidebarWidth(SidebarWidth());

    const handleResize = () => {
      setSidebarWidth(SidebarWidth());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!auth) {
      navigate('/login');
    }

    const subscription = utils.OnTransition().subscribe((transitionState) => {
      setTransition(transitionState);
    });

    return () => subscription.unsubscribe();
  }, [auth, navigate, utils]);

  return (
    <Layout >
      <Sider
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        breakpoint="lg"
        collapsedWidth={sidebarWidth}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          insetInlineStart: 0,
          top: 0,
          bottom: 0,
          scrollbarWidth: 'thin',
          scrollbarGutter: 'stable',
          paddingTop: '10px',
        }}
      >
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          selectedKeys={[GetSelected(location)]}
          mode="inline"
          items={menuItems}
        />
      </Sider>
      <Layout style={{
        marginInlineStart: collapsed ? SidebarWidth() : 200,
        transition: 'margin 0.2s ease',
      }}>
        <Header
          style={{
            padding: 10,
            background: colorBgContainer,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingInline: '16px',
          }}
        >
          <div className='flex align-middle justify-start'>
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: 'trigger',
                onClick: () => setCollapsed(!collapsed),
                style: { fontSize: '18px', cursor: 'pointer' },
              }
            )}
            <Breadcrumb className='ms-4'>
              {breadcrumbs.map((breadcrumb, index) => (
                <Breadcrumb.Item key={index}>
                  {breadcrumb.path ? (
                    <Link to={breadcrumb.path} style={{ color: "#001529" }}>{breadcrumb.label}</Link>
                  ) : (
                    breadcrumb.label
                  )}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </div>
          <Dropdown
            text={{ value: `Welcome, ${auth?.session.name}`, weight: 200, size: 'text-md' }}
            color="blue"
            tone={500}
            items={[
              {
                icon: <LogoutOutlined />,
                label: 'Logout',
                visible: true,
                onClick: () => {
                  resetAuth();
                  navigate('/login');
                },
              },
              {
                icon: <ProfileOutlined />,
                visible: true,
                label: 'My Profile',
                onClick: () => navigate('/private/profile'),
              },
              {
                label: 'Change Password',
                visible: true,
                onClick: () => navigate('/private/profile/update-password'),
              },
            ]}
            disabled={false}
          />
        </Header>
        <div className='relative'>
          <Spinner visible={transition} />

          <Outlet />
        </div>
      </Layout>
    </Layout>
  );
};

export default PrivateLayout;