import { useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import type { InputRef, TableColumnType } from 'antd';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';
import { Button, Input, Select, Space } from 'antd';
import CustomButton from '../Button';
import { ITableAction, ITableColumnType } from '../../interfaces';

type DataIndex<T> = keyof T;

interface TableLogicOptions<T> {
    columns: ITableColumnType<T>[];
    data: T[];
    actions: ITableAction<T>;
}

const Configure = <T extends object>({ columns, data, actions }: TableLogicOptions<T>) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState<DataIndex<T> | ''>('');
    const searchInput = useRef<InputRef>(null);

    const handleSearch = (
        selectedKeys: string[],
        confirm: FilterDropdownProps['confirm'],
        dataIndex: DataIndex<T>,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (
        clearFilters: () => void,
        confirm: FilterDropdownProps['confirm'],
    ) => {
        confirm();
        clearFilters();
        setSearchText('');
        setSearchedColumn('');
        confirm();
    };

    const getColumnSearchProps = (dataIndex: DataIndex<T>): TableColumnType<T> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${String(dataIndex)}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        size="small"
                        style={{ width: 70 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                        size="small"
                        style={{ width: 60 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => close()}
                    >
                        Close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            String(record[dataIndex])
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const getBooleanColumnSearchProps = (dataIndex: DataIndex<T>): TableColumnType<T> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Select
                    placeholder={`Select ${String(dataIndex)}`}
                    value={selectedKeys[0]}
                    onChange={(value) => setSelectedKeys(value ? [value] : [])}
                    style={{ marginBottom: 8, display: 'block' }}
                >
                    <Select.Option value="true">YES</Select.Option>
                    <Select.Option value="false">NO</Select.Option>
                </Select>
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        size="small"
                        style={{ width: 70 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                        size="small"
                        style={{ width: 60 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => close()}
                    >
                        Close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            String(record[dataIndex]) === value,
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.focus(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const enhancedColumns = columns.map((column) => {
        if (!column.dataIndex) {
            return column;
        }

        return {
            ...column,
            ...(
                column.filter ?
                    (
                        column.boolean ?
                            getBooleanColumnSearchProps(column.dataIndex as DataIndex<T>) :
                            getColumnSearchProps(column.dataIndex as DataIndex<T>)
                    ) :
                    {}
            ),
            render: column.render,
            className: `${column.className || ''} text-center justify-center`
        };
    });

    if (actions.visible) {
        enhancedColumns.push({
            title: 'Actions',
            key: 'actions',
            ...(actions.fixed && actions.fixed !== 'none' ? { fixed: actions.fixed } : {}),
            render: (_, record: T) => (
                <div className="flex justify-center items-center space-x-2">
                    {actions.buttons?.map((action, index) => (
                        <CustomButton
                            key={index}
                            icon={action.icon}
                            text={action.text}
                            color={action.color}
                            tone={action.tone}
                            disabled={action.disabled}
                            btnClass={action.btnClass}
                            onClick={() => action.onClick(record)}
                            title={action.title}
                        />
                    ))}
                </div>
            ),
        })
    }


    return { columns: enhancedColumns, data };
};

export default Configure;