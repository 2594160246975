const WeightsClasses: { [key in Weights]: string } = {
    100: 'font-thin',
    200: 'font-extralight',
    300: 'font-light',
    400: 'font-normal',
    500: 'font-medium',
    600: 'font-semibold',
    700: 'font-bold',
    800: 'font-extrabold',
    900: 'font-black',
};

type Weights = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

export { WeightsClasses }
export type { Weights }