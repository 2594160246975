import { IButton } from '../interfaces';
import Button from './Button';
import React from 'react';

interface Props {
  title: string;
  placeholder?: string;
  type: string;
  id?: string;
  inputClass?: string;
  value?: string;
  name?: string;
  disabled?: boolean;
  simple?: boolean;
  simplestate?: boolean;
  buttons?: IButton[];
  OnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FC<Props> = ({ title, placeholder = '', type, id = '', inputClass = '', value = '', name = '', disabled, simple = false, simplestate = false, buttons, OnChange }) => {
  return (
    <div className={`${inputClass}`}>
      {
        (!simple || !simplestate) && (
          <span className="text-left block mb-2 text-sm font-medium text-gray-600">{title}</span>
        )
      }

      <div className={`${buttons ? 'flex align-middle justify-center' : ''}`}>
        <input
          className="bg-white border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1.5 px-2 focus:bg-white "
          type={type}
          id={id}
          placeholder={!simple || !simplestate ? placeholder: title}
          value={value}
          name={name}
          onChange={OnChange}
          disabled={disabled}
        />

        {
          buttons?.map((b, index) => (
            <Button
              key={index + 1}
              icon={b.icon}
              text={b.text}
              color={b.color}
              tone={b.tone}
              disabled={b.disabled}
              btnClass={`${b.btnClass} ms-2`}
              onClick={b.onClick}
              title={b.title}
            />
          ))
        }
      </div>

    </div>
  );
};

export default Input;