import { Job } from "../../../../../core/models/jobs/job.model";
import { BaseService } from "../../../../../core/services/base.service";
import { User } from "../model/user.model";

export class ProfileService {
    static async Get(): Promise<User> {
        return await BaseService.Get("/users/profile");
    }

    static async Patch(user: User): Promise<Job[]> {
        return await BaseService.Patch("/users/profile", {
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            phoneNumber: user.phoneNumber,
            whatsappNotifiable: user.whatsappNotifiable,
            emailNotifiable: user.emailNotifiable
        });
    }
}