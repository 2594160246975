import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { createStyles } from 'antd-style';

interface TableComponentProps<T> {
    dataSource: T[];
    columns: TableColumnsType<T>;
    rowKey: string | ((record: T) => string);
    defaultPageSize?: number;
}

const useStyle = createStyles(({ css }) => {
    return {
        Table: css`
            .ant-table {
                .ant-table-container {
                    .ant-table-body,
                    .ant-table-content {
                    scrollbar-width: thin;
                    scrollbar-color: #eaeaea transparent;
                    scrollbar-gutter: stable;
                    }
                }
            }

            .ant-table-thead > tr > th {
                text-align: center;
            }
        `,
    };
});
const CustomTable = <T extends object>({ dataSource, columns, rowKey, defaultPageSize = 10 }: TableComponentProps<T>) => {
    const { styles } = useStyle();

    return (
        <Table<T>
            columns={columns}
            dataSource={dataSource}
            className={styles.Table}
            rowKey={rowKey}
            pagination={{
                position: ['bottomRight'],
                showSizeChanger: true,
                pageSizeOptions: ['5', '10', '20', '50'],
                defaultPageSize,
            }}
            scroll={{ x: 1000 }}
            getPopupContainer={() => document.getElementById('Table') || document.body}
        />
    );
};

export default CustomTable;
