import { Pageable } from "../sort/pageable.model";
import { Sort } from "../sort/sort.model";
import { JobApplication } from "./job-application.model";

export class JobApplicationDto {
    constructor(
        public totalPages: number = 0,
        public totalElements: number = 0,
        public size: number = 0,
        public content: JobApplication[] = [],
        public number: number = 0,
        public sort: Sort = new Sort(),
        public pageable: Pageable = new Pageable(),
        public numberOfElements: number = 0,
        public first: boolean = false,
        public last: boolean = false,
        public empty: boolean = false,
    ) {}
}