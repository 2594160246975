import JobApplicationTable from '../job-application/components/JobApplicationTable';
import FeatureJobTable from '../job/components/JobTable';
import React from 'react';

const Dashboard: React.FC = () => {
  return (
    <>
      <FeatureJobTable action={false} />

      <JobApplicationTable dashboard={true} />
    </>
  );
};

export default Dashboard;
