import { Button, Container, Dropdown, Header, Input, Quill, Select } from '../../../../../core/components';
import { SelectValue } from '../../../../../core/models/components/select-value.model';
import { Category } from '../../../../../core/models/jobs/category.model';
import { UtilService } from '../../../../../core/services/util.service';
import { CloseCircleOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined, EyeOutlined, FileTextOutlined, SaveOutlined } from '@ant-design/icons';
import { CategoryService } from '../../../services/category.service';
import { Job } from '../../../../../core/models/jobs/job.model';
import React, { useEffect, useState } from 'react';
import { JobService } from '../services/job.service';
import { useNavigate, useParams } from 'react-router-dom';
import { useJobStore } from '../../../../../core/store/job.store';

interface Props {
    DisposeForm?: () => void;
}

const JobForm: React.FC<Props> = ({ DisposeForm = () => { } }) => {
    const [subCategories, setSubCategories] = useState<SelectValue[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [item, setItem] = useState<Job>(new Job());
    const { id } = useParams<{ id?: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        const Get = async () => {
            try {
                if (id) {
                    const job: Job = await JobService.GetJob(id, (error: Error) => {
                        if (error.message.includes("400")) {
                            UtilService.Alert('Important!', 'info', 'The job applicattion entered does not exist');

                        } else {
                            UtilService.Alert('Important!', 'info', 'Internal Server Error');
                        }

                        GoBack();
                    });

                    setItem(job);
                }

                const categories = await CategoryService.Get();

                setCategories(categories);
            } catch {

            }
        };

        Get();
    }, [id]);

    const GoBack = () => {
        navigate("/private/job")
    };

    const Save = async (item: Job, draft: boolean = false, published: boolean = false) => {
        const job: Job = {
            ...item,
            draft,
            published
        };

        const errors = Validate(job);

        if (errors.length > 0) {
            UtilService.Alert('Important!', 'info', errors.join(' </br> '));

            return;
        }

        await JobService.Add(job);

        UtilService.Alert('Success!', 'success', 'The Job has been created.', undefined, () => {
            GoBack();
        });
    }

    const Publish = async () => {
        await JobService.Publish(item.id);

        UtilService.Alert('Success!', 'success', 'The Job has been published.', undefined, () => {
            GoBack();
        });
    }

    const Validate = (job: Job) => {
        const errors: string[] = [];

        if (job.category.length === 0) {
            errors.push("You must select the category.");
        }

        if (job.subCategory.length === 0) {
            errors.push("You must select the subcategory.");
        }

        if (job.name.length === 0) {
            errors.push("You must enter the name.");
        }

        if (job.company.length === 0) {
            errors.push("You must enter the company.");
        }

        if (job.location.length === 0) {
            errors.push("You must select the location.");
        }

        if (job.description === "<p><br></p>") {
            errors.push("You must enter the description.");
        }

        return errors;
    }

    const HandlePreview = () => {
        if (id) {
            window.open(`/#/private/job/${item.id}/preview`, '_blank');

            return;
        }

        const errors = Validate(item);

        if (errors.length > 0) {
            UtilService.Alert('Important!', 'info', errors.join(' </br> '));

            return;
        }

        useJobStore.getState().setJob(item);

        window.open(`/#/private/job/preview`, '_blank');
    };

    const OnChangeQuill = (name: string, value: string) => {
        setItem((prevItem) => ({
            ...prevItem,
            [name]: value,
        }));
    };

    const OnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setItem((prevItem) => ({
            ...prevItem,
            [name]: value,
        }));
    };

    const OnChangeSelect = (name: string, value: string, callback?: (value: string) => void) => {
        setItem((prevItem) => ({
            ...prevItem,
            [name]: value,
        }));

        callback?.(value);
    };

    const Categories = (): SelectValue[] => {
        let options = [];

        options.push(
            ...categories.map((category) => ({
                label: category.name,
                value: category.id,
            }))
        );

        return options;
    }

    const SubCategories = (val: string): SelectValue[] => {
        let subcategories = [];

        subcategories.push(
            ...categories
                .filter((x) => x.id === val)
                .flatMap((category) =>
                    category.subCategories.map((subcategory) => ({
                        label: subcategory,
                        value: subcategory,
                    }))
                )
        );

        return subcategories;
    }

    const Locations = (): SelectValue[] => {
        let locations = [];

        locations.push(
            {
                label: "Remote",
                value: "Remote"
            },
            {
                label: "On Site",
                value: "On-Site"
            },
            {
                label: "Hybrid",
                value: "Hybrid"
            },
        );

        return locations;
    }

    const Delete = async () => {
        await JobService.Delete(item.id);

        UtilService.Alert('Success!', 'success', "The Job has been deleted.", undefined, () => {
            GoBack();
        });
    }

    return (
        <Container>
            <div className="mx-auto">
                <Header
                    title='Jobs'
                    subtitle='Data Form'
                    buttons={[
                        {
                            title: "Close",
                            icon: {
                                value: <CloseCircleOutlined />,
                                size: "text-lg"
                            },
                            color: "red",
                            text: {
                                size: 'md',
                                value: 'Close',
                                weight: 500
                            },
                            tone: 500,
                            btnClass: 'mr-1',
                            onClick: () => GoBack()
                        }
                    ]}
                />

                <div className="grid grid-cols-1 lg:grid-cols-2 mb-5 gap-x-5 gap-y-5">
                    <Input
                        title='Job Name'
                        placeholder='Enter the Job Name...'
                        type='string'
                        id='name'
                        name="name"
                        value={item.name}
                        simple={true}
                        simplestate={item.id.length === 0}
                        inputClass=''
                        OnChange={OnChangeInput}
                    />

                    <Input
                        title='Company'
                        placeholder='Enter the Company...'
                        type='string'
                        id='name'
                        name="company"
                        value={item.company}
                        simple={true}
                        simplestate={item.id.length === 0}
                        inputClass=''
                        OnChange={OnChangeInput}
                    />
                </div>

                <div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 mb-5 gap-x-5 gap-y-5">
                        <Select
                            title="Category"
                            placeholder="Select an option"
                            options={Categories()}
                            name="category"
                            selectClass=""
                            value={item.category}
                            simple={true}
                            simplestate={item.id.length === 0}
                            onChange={(name, value) => OnChangeSelect(name, value, (id) => {
                                let finded = categories.find(x => x.id === id)!;

                                if (!finded || !finded.name) {
                                    return;
                                }

                                let subcategories = SubCategories(id);

                                setItem((prevItem) => ({
                                    ...prevItem,
                                    category: finded.name || "",
                                    subCategory: "",
                                }));

                                setSubCategories(subcategories);
                            })}
                        />

                        <Select
                            title="Sub Category"
                            placeholder="Select an option"
                            options={subCategories}
                            name="subCategory"
                            selectClass=""
                            value={item.subCategory}
                            simple={true}
                            simplestate={item.id.length === 0}
                            onChange={OnChangeSelect}
                            disabled={item.category === ""}
                        />

                        <Select
                            title="Location"
                            placeholder="Select an option"
                            options={Locations()}
                            name="location"
                            selectClass=""
                            simple={true}
                            simplestate={item.id.length === 0}
                            value={item.location}
                            onChange={OnChangeSelect}
                        />
                    </div>
                </div>



                <div className="grid grid-cols-1 mb-5 gap-x-5 gap-y-5">
                    <Quill
                        title='Description'
                        placeholder='Enter the Description...'
                        id='description'
                        name="description"
                        value={item.description}
                        simple={true}
                        simplestate={item.id.length === 0}
                        OnChange={OnChangeQuill}
                    />
                </div>

                <div className='flex items-center justify-end mt-10'>
                    <Dropdown
                        text={{ value: "Actions", weight: 400, size: 'text-md' }}
                        color="blue"
                        tone={500}
                        items={[
                            {
                                icon: <DeleteOutlined />,
                                label: 'Delete',
                                visible: (id !== undefined),
                                onClick: async () => { await Delete() },
                            },
                            {
                                icon: <EditOutlined />,
                                label: 'Save As Draft',
                                visible: (!item.published || !id),
                                onClick: async () => { await Save(item, true) },
                            },
                            {
                                icon: <CloudUploadOutlined />,
                                label: 'Publish',
                                visible: (!item.published && id !== undefined),
                                onClick: async () => { await Publish() },
                            },
                            {
                                icon: <EyeOutlined />,
                                label: 'Preview',
                                visible: true,
                                onClick: async () => { HandlePreview() },
                            },
                        ]}
                        disabled={false}
                    />
                </div>
            </div>
        </Container>
    );
};

export default JobForm;