import { ColorsClasses, WeightsClasses } from '../constants';
import { UtilService } from '../services/util.service';
import React, { useEffect, useState } from 'react';
import { IButton } from '../interfaces';
import { Tooltip } from 'antd';

const Button: React.FC<IButton> = ({ icon, title, text, color, tone, disabled = false, btnClass, onClick }) => {
  const utils = UtilService.GetInstance();
  const [transition, setTransition] = useState(false);

  useEffect(() => {
    const subscription = utils.OnTransition().subscribe((transition) => {
      setTransition(transition);
    });

    return () => subscription.unsubscribe();
  }, [utils]);

  const colorClass = ColorsClasses[color]?.[tone] || 'bg-gray-500 hover:bg-gray-600';
  const weightClass = WeightsClasses[text?.weight ?? 400];

  return (
    <Tooltip title={title} placement='bottom'>
      <div className='flex align-middle justify-center'>
        <button
          className={`${btnClass} h-8 text-white font-bold py-2 rounded flex items-center justify-center transition duration-300 ${colorClass} active:scale-95 hover:scale-105 px-2`}
          disabled={transition || disabled}
          onClick={onClick}
          title=''

        >
          {icon && <span className={`${text ? 'mr-2' : ''} ${icon.size}`}>{icon.value}</span>}
          {text && <span className={`${weightClass} ${text.size}`}>{text.value}</span>}
        </button>
      </div>
    </Tooltip>
  );
};

export default Button;