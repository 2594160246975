import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { createStyles } from 'antd-style';

interface TableComponentProps<T> {
    dataSource: T[];
    columns: TableColumnsType<T>;
    rowKey: string | ((record: T) => string);
    records: number;
    OnChange: (page: number, pageSize: number) => Promise<void>;
    OnShowSizeChange: (current: number, size: number) => Promise<void>;
}

const useStyle = createStyles(({ css }) => {
    return {
        Table: css`
            .ant-table {
                .ant-table-container {
                    .ant-table-body,
                    .ant-table-content {
                    scrollbar-width: thin;
                    scrollbar-color: #eaeaea transparent;
                    scrollbar-gutter: stable;
                    }
                }
            }

            .ant-table-thead > tr > th {
                text-align: center;
            }
        `,
    };
});
const ServerTable = <T extends object>({ dataSource, columns, rowKey, records, OnChange, OnShowSizeChange }: TableComponentProps<T>) => {
    const { styles } = useStyle();

    const onChange = (page: number, pageSize: number) => {
        OnChange(page, pageSize);
    };

    const onShowSizeChange = (current: number, size: number) => {
        OnShowSizeChange(current, size);
    };

    return (
        <Table<T>
            columns={columns}
            dataSource={dataSource}
            className={styles.Table}
            rowKey={rowKey}
            pagination={{
                position: ['bottomRight'],
                showSizeChanger: true,
                pageSizeOptions: ['5', '10', '20', '50'],
                defaultPageSize: 10,
                total: records,
                defaultCurrent: 1,
                onChange,
                onShowSizeChange,
            }}
            scroll={{ x: 1000 }}
            getPopupContainer={() => document.getElementById('Table') || document.body}
        />
    );
};

export default ServerTable;
