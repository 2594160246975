
import { Content } from 'antd/es/layout/layout';
import { theme } from 'antd';
import React from 'react';

interface Props {
    children: React.ReactNode;
}

const Container: React.FC<Props> = ({ children }) => {
    const { token: { colorBgContainer, borderRadiusLG } } = theme.useToken();

    return (
        <Content style={{ margin: '24px 24px 24px', overflow: 'initial' }}>
            <div
                style={{
                    padding: 24,
                    textAlign: 'center',
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                    position: "relative"
                }}
            >
                {children}
            </div>
        </Content>
    );
};

export default Container;