import { AccessToken } from "../../../core/models/auth/access-token.model";
import { UtilService } from "../../../core/services/util.service";
import { User } from "../models/user.model";

export class AuthService {
    private static readonly url: string = process.env.REACT_APP_API_URL!;

    private static readonly utils: UtilService = UtilService.GetInstance();

    private static async Request(method: string, segment: string, body: object, CallbackCatch: (error: Error)=> void, spinner: boolean = true): Promise<any> {
        try {
            if(spinner){
                this.utils.transitionSubject.next(true);
            }

            const response = await fetch(this.url + segment, {
                method,
                headers: {
                    'accept': '*/*',
                    'Content-Type': 'application/json'
                },
                body: body ? JSON.stringify(body) : undefined
            });

            if (response.status === 204) {
                return;
            }

            let data = await response.json()
            
            if (response.ok) {
                return data;
            }
            
            if (response.status === 401) {
                throw new Error("UNAUTHORIZED");
            }

            if (response.status === 404) {
                throw new Error("NOT FOUND");
            }

            const message = data.message || `HTTP error! Status: ${response.status}`;

            throw new Error(message);
        } catch (error: any) {
            CallbackCatch(error);
        }finally {
            if(spinner){
                this.utils.transitionSubject.next(false);
            }
        }
    }

    static async Login(user: User): Promise<AccessToken> {
        return await this.Request("POST", "/public/login", user, (error: Error) => {
            if (error.message.includes("UNAUTHORIZED")) {
                UtilService.Alert('Important!', 'info', 'Incorrect username or password');
            } else {
                UtilService.Alert('Important!', 'info', 'Internal Server Error');
            }
        });
    }
}