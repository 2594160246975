import { IButton } from '../interfaces';
import { Button } from '../components';
import React from 'react';

interface Props {
    title: string;
    subtitle?: string;
    buttons?: IButton[];
}

const Header: React.FC<Props> = ({ title, subtitle, buttons }) => {
    const subtitleVisible: boolean = process.env.REACT_APP_SUBTITLES_VISIBLES === 'true';

    return (
        <>
            <div className="border-1 flex justify-between mb-4">
                <h3 className="flex flex-col items-start">
                    <span className={`font-bold text-2xl ${subtitle ? "mb-1" : ""}`}>{title}</span>

                    {
                        subtitleVisible && subtitle && (
                            <span className="text-gray-600 mt-1 font-semibold text-sm">{subtitle}</span>
                        )
                    }
                </h3>

                <div className="flex items-center">
                    {buttons?.map((button, index) => (
                        <div key={index} title={button.title}>
                            <Button {...button} />
                        </div>
                    ))}
                </div>
            </div>

            <div className="border-dashed border border-gray-400 mb-4 mt-4"></div>
        </>
    );
};

export default Header;