import { JobApplicationStatus } from "../../../../../core/models/jobs/job-application-status.model";
import { JobApplicationDto } from "../../../../../core/models/jobs/job-application-dto.model";
import { JobApplication } from "../../../../../core/models/jobs/job-application.model";
import { Pagination } from "../../../../../core/models/sort/pagination.model";
import { BaseService } from "../../../../../core/services/base.service";

export class JobApplicationService {
    static async GetApplications(pagination: Pagination): Promise<JobApplicationDto> {
        return await BaseService.Get(`/job-applications?page=${pagination.page}&size=${pagination.size}`, undefined, false);
    }

    static async GetApplicationsById(id: string, CallbackCatch: (error: Error) => void): Promise<JobApplication[]> {
        return await BaseService.Get(`/featured/jobs/${id}/job-applications`, CallbackCatch, false);
    }

    static async GetById(id: string, CallbackCatch?: (error: Error) => void): Promise<JobApplication> {
        return await BaseService.Get(`/job-applications/{id}?id=${id}`, CallbackCatch, false);
    }

    static async GetStatuses(id: string): Promise<string[]> {
        return await BaseService.Get(`/job-applications/{id}/genericStatuses?id=${id}`, undefined, false);
    }
   
    static async GetFile(id: string, fileId: string): Promise<Blob> {
        return await BaseService.Blob('GET', `/job-applications/{id}/files/{fileId}?id=${id}&fileId=${fileId}`, undefined, undefined, false);   
    }
   
    static async Add(id: string, status: JobApplicationStatus, notifiable: boolean): Promise<JobApplication> {
        return await BaseService.Post(`/job-applications/${id}/statuses`, {
            status: status.statusName,
            content: status.note,
            notifiable
        });
    }
}