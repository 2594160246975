import React from 'react';
import './Spinner.css';

interface Props {
    visible: boolean;
}

const Spinner: React.FC<Props> = ({ visible = true }) => {
    return (
        <>
            {visible && (
                <div className="spinner-container">
                    <span className="spinner"></span>
                </div>
            )}
        </>
    );
};

export default Spinner;