import { JobApplicationStatus } from '../../../../../core/models/jobs/job-application-status.model';
import { ITableAction, ITableColumnType } from '../../../../../core/interfaces';
import { Table, TableConfigure } from '../../../../../core/components';
import { Collator } from '../../../../../core/constants';
import { EyeOutlined } from '@ant-design/icons';
import React from 'react';

interface Props {
    data: JobApplicationStatus[];
    ShowModal: (item: JobApplicationStatus) => void;
}

const columns: ITableColumnType<JobApplicationStatus>[] = [
    {
        title: 'Date (YYYY-MM-DD)',
        dataIndex: 'createdDate',
        key: 'createdDate',
        filter: true,
        sorter: (a: JobApplicationStatus, b: JobApplicationStatus) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime(),
    },
    {
        title: 'Status',
        key: 'statusName',
        filter: true,
        sorter: (a: JobApplicationStatus, b: JobApplicationStatus) => Collator.compare(a.statusName, b.statusName),
        render: (e: JobApplicationStatus) => <div className='text-center'>{e.statusName?.replace("_", " ")}</div>
    }
];

const JobApplicationHistoryTable: React.FC<Props> = (({ data, ShowModal }) => {
    const actions: ITableAction<JobApplicationStatus> = {
        visible: true,
        buttons: [
            {
                title: "View",
                icon: {
                    value: <EyeOutlined />,
                    size: 'text-lg',
                },
                color: 'blue',
                tone: 500,
                onClick: (item) => {
                    ShowModal(item);
                }
            }
        ],
        fixed: "none"
    };

    const { columns: enhancedColumns, data: enhancedData } = TableConfigure<JobApplicationStatus>({ columns, data, actions });

    return (
        <div>
            <Table<JobApplicationStatus> columns={enhancedColumns} dataSource={enhancedData} rowKey="id" defaultPageSize={5} />
        </div>
    );
});

export default JobApplicationHistoryTable;