import { Input } from 'antd';
import React from 'react';
import './TextArea.css'
const AntTextArea = Input.TextArea;

interface Props {
  title: string;
  placeholder: string;
  id?: string;
  textAreaClass?: string;
  maxHeight?: string;
  value: string;
  rows?: number;
  name: string;
  simple?: boolean;
  simplestate?: boolean;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea: React.FC<Props> = ({ title, placeholder, id, textAreaClass, maxHeight, value, rows = 4, name, simple = false, simplestate = false, disabled = false, onChange }) => {
  return (
    <div className={textAreaClass}>
      {
        (!simple || !simplestate) && (
          <span className="text-left block mb-2 text-sm font-medium text-gray-600">{title}</span>
        )
      }

      <AntTextArea
        className={`bg-white border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 focus:bg-white ${maxHeight}`}
        id={id}
        name={name}
        placeholder={!simple || !simplestate ? placeholder: title}
        value={value}
        rows={rows}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

export default TextArea;
