import { JobApplicationDto } from '../../../../../core/models/jobs/job-application-dto.model';
import { Pagination } from '../../../../../core/models/sort/pagination.model';
import { JobApplicationService } from '../services/job-application.service';
import React, { useCallback, useEffect, useState } from 'react';
import TableComponent from './TableComponent';

interface Props {
    dashboard?: boolean;
}

const JobApplicationTable: React.FC<Props> = (({ dashboard = false }) => {
    const [pagination, setPagination] = useState<Pagination>(new Pagination());
    const [dto, setDto] = useState<JobApplicationDto>(new JobApplicationDto());

    const GetApplications = useCallback(async () => {
        const result: JobApplicationDto = await JobApplicationService.GetApplications(pagination);

        setDto(result);
    }, []);

    useEffect(() => {
        GetApplications();
    }, [GetApplications]);

    return (
        <TableComponent
            tableType={1}
            cols={{ jobName: true }}
            state={{
                from: 1
            }}
            data={dto.content}
            pagination={{
                records: dto.totalElements,
                async OnChange(page, pageSize) {
                    setPagination(new Pagination(page, pageSize))

                    GetApplications();
                },
                async OnShowSizeChange(current, size) {
                    setPagination(new Pagination(current, size))

                    GetApplications();
                },
            }}
            dashboard={dashboard}
        />
    );
});

export default JobApplicationTable;