import { AccessTokenPayload } from '../../../../../core/models/auth/access-token-payload.model';
import { AccessToken } from '../../../../../core/models/auth/access-token.model';
import { AuthService } from '../../../../public/services/auth-login.service';
import { UtilService } from '../../../../../core/services/util.service';
import { Session } from '../../../../../core/models/auth/session.model';
import { useAuthStore } from '../../../../../core/store/auth.store';
import { Container, Input } from '../../../../../core/components';
import { User } from '../../../../public/models/user.model';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

const UpdatePassword: React.FC = () => {
    const navigate = useNavigate();
    const { setAuth } = useAuthStore();
    const [user, setUser] = useState<User>(new User());

    const Validate = (user: User, callback: () => void) => {
        const errors: string[] = [];

        if (user.username.length === 0) {
            errors.push("You must enter the username.");
        }

        if (user.password.length === 0) {
            errors.push("You must enter the password.");
        }

        if (errors.length > 0) {
            UtilService.Alert('Important!', 'info', errors.join(' </br> '));

            return;
        }

        callback();
    }

    const Login = async () => {
        const item: AccessToken = await AuthService.Login(user);

        if (item) {
            const payload: AccessTokenPayload = UtilService.DecodeJWT(item.accessToken);

            const session: Session = {
                session_state: payload.session_state,
                realm_access: payload.realm_access,
                resource_access: payload.resource_access,
                scope: payload.scope,
                sid: payload.sid,
                email_verified: payload.email_verified,
                name: payload.name,
                preferred_username: payload.preferred_username,
                given_name: payload.given_name,
                family_name: payload.family_name,
                email: payload.email,
            };

            setAuth(item, payload, session);

            navigate('/private/dashboard');
        }

    }

    return (
        <Container>
            <div className="flex min-full flex-col justify-center px-6 py-14 lg:px-8">
                <div className="max-w-[320px] w-full m-auto">
                    <div className="sm:mx-auto w-full">
                        <h2 className="mt-4 text-center text-2xl/9 font-bold tracking-tight text-gray-900">Update Password</h2>
                        <h5 className="mt-2 text-center text-lg tracking-tight text-gray-900">Enter your new password to update it</h5>
                    </div>

                    <div className="mt-10 sm:mx-auto w-full">
                        <div className="space-y-6">
                            <div>
                                <Input
                                    title='New Password'
                                    placeholder='Enter your password...'
                                    type='string'
                                    id='firtsname'
                                    name="firtsname"
                                />
                            </div>

                            <div>
                                <Input
                                    title='Confirm Password'
                                    placeholder='Enter your password...'
                                    type='string'
                                    id='firtsname'
                                    name="firtsname"
                                />
                            </div>

                            <div>
                                <button
                                    onClick={() => Validate(user, Login)}
                                    className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >Update your Password</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default UpdatePassword;