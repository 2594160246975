import { Button, CheckBox, Container, Header, Input } from '../../../../../core/components';
import { MailOutlined, PhoneOutlined, SaveOutlined } from '@ant-design/icons';
import { UtilService } from '../../../../../core/services/util.service';
import { ICheckboxOption } from '../../../../../core/interfaces';
import { ProfileService } from '../service/profile.service';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { useEffect, useState } from 'react';
import { User } from '../model/user.model';

const Profile: React.FC = () => {
  const [user, setUser] = useState<User>(new User());
  const [selecteds, setSelecteds] = useState<ICheckboxOption[]>([]);

  const emailNotifiable = { name: "ckbEmail", label: "Email", value: "ckbEmail", icon: <MailOutlined /> };
  const whatsappNotifiable = { name: "ckbWhatsApp", label: "WhatsApp", value: "ckbWhatsApp", icon: <PhoneOutlined /> };

  const options = [emailNotifiable, whatsappNotifiable];

  const Get = async () => {
    let user = await ProfileService.Get();
    const updateds = [];

    if (user.emailNotifiable) {
      updateds.push(emailNotifiable)
    }

    if (user.whatsappNotifiable) {
      updateds.push(whatsappNotifiable)
    }

    setUser(user);
    setSelecteds(updateds);
  }

  useEffect(() => {
    Get();
  }, []);

  const OnChangeSelected = (e: CheckboxChangeEvent, option: ICheckboxOption) => {
    setSelecteds((prevSelecteds) => {
      if (e.target.checked) {
        return [...prevSelecteds, option];
      } else {
        return prevSelecteds.filter((item) => item.value !== option.value);
      }
    });
  };

  const Validate = async(user: User, callback: () => Promise<void>) => {
    const errors: string[] = [];

    if (user.firstname.length === 0) {
      errors.push("You must enter the firtsname.");
    }

    if (user.lastname.length === 0) {
      errors.push("You must enter the lastname.");
    }

    if (user.email.length === 0) {
      errors.push("You must enter the email.");
    }

    if (errors.length > 0) {
      UtilService.Alert('Important!', 'info', errors.join(' </br> '));

      return;
    }

    await callback();
  }

  const Patch = async () => {
    let fixed = user;

    fixed.emailNotifiable = selecteds.some(x => x.value === "ckbEmail");
    fixed.whatsappNotifiable = selecteds.some(x => x.value === "ckbWhatsApp");

    await ProfileService.Patch(fixed)

    await Get();

    UtilService.Alert('Success!', 'success', 'The user has been update.');
  }

  const OnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setUser((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };


  return (
    <Container>
      <Header
        title='My Profile'
        subtitle='Administration'
        buttons={[]}
      />

      <div>
        <div className="grid grid-cols-1 lg:grid-cols-2 mb-5 gap-x-5 gap-y-5">
          <Input
            title='Firts Name'
            placeholder='Enter the Firts Name...'
            type='string'
            id='firstname'
            name="firstname"
            value={user.firstname}
            OnChange={OnChangeInput}
          />

          <Input
            title='Last Name'
            placeholder='Enter the Last Name...'
            type='string'
            id='lastname'
            name="lastname"
            value={user.lastname}
            OnChange={OnChangeInput}
          />
        </div>

        <div className="grid grid-cols-1 mb-5 gap-x-5 gap-y-5">
          <Input
            title='Email'
            placeholder='Enter the Email...'
            type='string'
            id='email'
            name="email"
            value={user.email}
            OnChange={OnChangeInput}
          />
        </div>

        <div className="grid grid-cols-1 mb-5 gap-x-5 gap-y-3">
          <div className='flex items-center justify-start mt-0'>
            <CheckBox
              title="Be notified On"
              options={options}
              selected={selecteds}
              onChange={OnChangeSelected}
              layout="horizontal"
            />
          </div>
        </div>

        <div className='flex items-center justify-end mt-6'>
          <Button
            icon={{ value: <SaveOutlined />, size: 'text-lg' }}
            text={{ value: 'Update', weight: 400, size: 'text-md' }}
            color='blue'
            tone={500}
            onClick={() => { Validate(user, async () => { await Patch() }) }}
          />
        </div>
      </div>
    </Container>
  );
};

export default Profile;