import { CheckboxChangeEvent } from "antd/es/checkbox";
import { ICheckboxOption } from "../interfaces";
import { Checkbox, Col, Row } from "antd";
import React, { useEffect } from "react";

interface Props {
    title: string;
    options: ICheckboxOption[];
    selected: ICheckboxOption[];
    onChange: (e: CheckboxChangeEvent, option: ICheckboxOption) => void;
    layout?: "horizontal" | "vertical";
}

const CheckboxComponent: React.FC<Props> = ({
    title,
    options,
    selected,
    onChange,
    layout = "vertical",
}) => {
    useEffect(() => {
    }, [selected]);

    return (
        <div className="checkbox-group-component">
            <span className="text-left block mb-2 text-sm font-medium text-gray-600">{title}</span>

            <Row gutter={[8, 8]} justify={layout === "horizontal" ? "center" : "start"}>
                {options.map((option) => (
                    <Col key={option.name} span={layout === "horizontal" ? 12 : 24}>
                        <Checkbox
                            name={option.name}
                            value={option.value}
                            checked={selected.some((x) => x.value === option.value)}
                            onChange={(e) => onChange(e, option)}
                        >
                            <div className="flex items-center">
                                {option.icon && <span className="mr-2">{option.icon}</span>}
                                <span>{option.label}</span>
                            </div>
                        </Checkbox>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default CheckboxComponent;
