import { UrlParam } from "../models/url-param.model";
import { useAuthStore } from "../store/auth.store";
import { UtilService } from "./util.service";

export class BaseService {
    private static readonly url: string = process.env.REACT_APP_API_URL!;

    private static readonly utils: UtilService = UtilService.GetInstance();

    private static async Request(method: string, segment: string, body?: object, CallbackCatch?: (error: Error)=> void, spinner: boolean = true): Promise<any> {
        try {
            if(spinner){
                this.utils.transitionSubject.next(true);
            }

            const auth = useAuthStore.getState().getAuth();

            const response = await fetch(this.url + segment, {
                method,
                headers: {
                    'accept': '*/*',
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${auth?.accessToken.accessToken}`
                },
                body: body ? JSON.stringify(body) : undefined
            });

            if (response.status === 401) {

                UtilService.Alert('Important!', 'info', 'Invalid Token. Please Login');

                window.location.href = '/#/login';

                return;
            }

            if (response.status === 204) {
                return;
            }

            let data = await response.json()
            
            if (response.ok) {
                return data;
            }

            if (response.status === 404) {
                throw new Error("NOT FOUND");
            }

            const message = data.message || `HTTP error! Status: ${response.status}`;

            throw new Error(message);
        } catch (error: any) {
            (CallbackCatch ?? this.Catch).call(this, error as Error);
        }finally {
            if(spinner){
                this.utils.transitionSubject.next(false);
            }
        }
    }

    public static async Blob(method: string, segment: string, body?: object, CallbackCatch?: (error: Error)=> void, spinner: boolean = true): Promise<any> {
        try {
            if(spinner){
                this.utils.transitionSubject.next(true);
            } 

            const auth = useAuthStore.getState().getAuth();

            const response = await fetch(this.url + segment, {
                method,
                headers: {
                    'accept': '*/*',
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${auth?.accessToken.accessToken}`
                },
                body: body ? JSON.stringify(body) : undefined
            });

            if (response.status === 204) {
                return;
            }

            let blob = await response.blob()
            
            if (response.ok) {
                return blob;
            }
            
            if (response.status === 401) {
                throw new Error("UNAUTHORIZED");
            }

            if (response.status === 404) {
                throw new Error("NOT FOUND");
            }

            let error = await response.json()

            const message = error.message || `HTTP error! Status: ${response.status}`;

            throw new Error(message);
        } catch (error: any) {
            (CallbackCatch ?? this.Catch).call(this, error as Error);
        } finally {
            if(spinner){
                this.utils.transitionSubject.next(false);
            }        
        }
    }

    private static Catch(error: Error){
        if(error?.message.includes("VALIDATION: ")){
            throw error;
        }

        let message: string = "Internal Server Error";

        if(error?.message.includes("SHOW: ")){
            message = error.message.replace("SHOW: ", "")
        }

        UtilService.Alert('Important!', 'info', message);
    }

    public static Get(segment: string, CallbackCatch?: (error: Error)=> void, spinner?: boolean): Promise<any> {
        return this.Request('GET', segment, undefined, CallbackCatch);
    }

    public static Post(segment: string, body: object, CallbackCatch?: (error: Error)=> void): Promise<any> {
        return this.Request('POST', segment, body, CallbackCatch);
    }

    public static Put(segment: string, body: object): Promise<any> {
        return this.Request('PUT', segment, body);
    }

    public static Delete(segment: string): Promise<any> {
        return this.Request('DELETE', segment);
    }

    public static Patch(segment: string, body?: object): Promise<any> {
        return this.Request('PATCH', segment, body);
    }

    public static BuildUrl(subroute: string, ...params: UrlParam[]): string {
        let newUrl: string = this.url + subroute;
        let firstParamAdded = false;

        params.forEach((param: UrlParam) => {
            if (param.value !== "") {
                if (!firstParamAdded) {
                    newUrl += `?${param.name}=${param.value}`;
                    firstParamAdded = true;
                } else {
                    newUrl += `&${param.name}=${param.value}`;
                }
            }
        });

        return newUrl;
    }
}