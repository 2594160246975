const ColorsClasses: { [key: string]: { [key: number]: string } } = {
    gray: {
        100: 'bg-gray-100 hover:bg-gray-200 disabled:bg-gray-50 disabled:text-gray-100',
        200: 'bg-gray-200 hover:bg-gray-300 disabled:bg-gray-100 disabled:text-gray-100',
        300: 'bg-gray-300 hover:bg-gray-400 disabled:bg-gray-200 disabled:text-gray-100',
        400: 'bg-gray-400 hover:bg-gray-500 disabled:bg-gray-300 disabled:text-gray-100',
        500: 'bg-gray-500 hover:bg-gray-600 disabled:bg-gray-400 disabled:text-gray-100',
        600: 'bg-gray-600 hover:bg-gray-700 disabled:bg-gray-500 disabled:text-gray-100',
        700: 'bg-gray-700 hover:bg-gray-800 disabled:bg-gray-600 disabled:text-gray-100',
        800: 'bg-gray-800 hover:bg-gray-900 disabled:bg-gray-700 disabled:text-gray-100',
        900: 'bg-gray-900 hover:bg-gray-800 disabled:bg-gray-800 disabled:text-gray-100',
    },
    blue: {
        100: 'bg-blue-100 hover:bg-blue-200 disabled:bg-blue-50 disabled:text-blue-100',
        200: 'bg-blue-200 hover:bg-blue-300 disabled:bg-blue-100 disabled:text-blue-100',
        300: 'bg-blue-300 hover:bg-blue-400 disabled:bg-blue-200 disabled:text-blue-100',
        400: 'bg-blue-400 hover:bg-blue-500 disabled:bg-blue-300 disabled:text-blue-100',
        500: 'bg-blue-500 hover:bg-blue-600 disabled:bg-blue-400 disabled:text-blue-100',
        600: 'bg-blue-600 hover:bg-blue-700 disabled:bg-blue-500 disabled:text-blue-100',
        700: 'bg-blue-700 hover:bg-blue-800 disabled:bg-blue-600 disabled:text-blue-100',
        800: 'bg-blue-800 hover:bg-blue-900 disabled:bg-blue-700 disabled:text-blue-100',
        900: 'bg-blue-900 hover:bg-blue-800 disabled:bg-blue-800 disabled:text-blue-100',
    },
    red: {
        100: 'bg-red-100 hover:bg-red-200 disabled:bg-red-50 disabled:text-red-100',
        200: 'bg-red-200 hover:bg-red-300 disabled:bg-red-100 disabled:text-red-100',
        300: 'bg-red-300 hover:bg-red-400 disabled:bg-red-200 disabled:text-red-100',
        400: 'bg-red-400 hover:bg-red-500 disabled:bg-red-300 disabled:text-red-100',
        500: 'bg-red-500 hover:bg-red-600 disabled:bg-red-400 disabled:text-red-100',
        600: 'bg-red-600 hover:bg-red-700 disabled:bg-red-500 disabled:text-red-100',
        700: 'bg-red-700 hover:bg-red-800 disabled:bg-red-600 disabled:text-red-100',
        800: 'bg-red-800 hover:bg-red-900 disabled:bg-red-700 disabled:text-red-100',
        900: 'bg-red-900 hover:bg-red-800 disabled:bg-red-800 disabled:text-red-100',
    },
    green: {
        100: 'bg-green-100 hover:bg-green-200 disabled:bg-green-50 disabled:text-green-100',
        200: 'bg-green-200 hover:bg-green-300 disabled:bg-green-100 disabled:text-green-100',
        300: 'bg-green-300 hover:bg-green-400 disabled:bg-green-200 disabled:text-green-100',
        400: 'bg-green-400 hover:bg-green-500 disabled:bg-green-300 disabled:text-green-100',
        500: 'bg-green-500 hover:bg-green-600 disabled:bg-green-400 disabled:text-green-100',
        600: 'bg-green-600 hover:bg-green-700 disabled:bg-green-500 disabled:text-green-100',
        700: 'bg-green-700 hover:bg-green-800 disabled:bg-green-600 disabled:text-green-100',
        800: 'bg-green-800 hover:bg-green-900 disabled:bg-green-700 disabled:text-green-100',
        900: 'bg-green-900 hover:bg-green-800 disabled:bg-green-800 disabled:text-green-100',
    },
    orange: {
        100: 'bg-orange-100 hover:bg-orange-200 disabled:bg-orange-50 disabled:text-orange-100',
        200: 'bg-orange-200 hover:bg-orange-300 disabled:bg-orange-100 disabled:text-orange-100',
        300: 'bg-orange-300 hover:bg-orange-400 disabled:bg-orange-200 disabled:text-orange-100',
        400: 'bg-orange-400 hover:bg-orange-500 disabled:bg-orange-300 disabled:text-orange-100',
        500: 'bg-orange-500 hover:bg-orange-600 disabled:bg-orange-400 disabled:text-orange-100',
        600: 'bg-orange-600 hover:bg-orange-700 disabled:bg-orange-500 disabled:text-orange-100',
        700: 'bg-orange-700 hover:bg-orange-800 disabled:bg-orange-600 disabled:text-orange-100',
        800: 'bg-orange-800 hover:bg-orange-900 disabled:bg-orange-700 disabled:text-orange-100',
        900: 'bg-orange-900 hover:bg-orange-800 disabled:bg-orange-800 disabled:text-orange-100',
    },
    indigo: {
        100: 'bg-indigo-100 hover:bg-indigo-200 disabled:bg-indigo-50 disabled:text-indigo-100',
        200: 'bg-indigo-200 hover:bg-indigo-300 disabled:bg-indigo-100 disabled:text-indigo-100',
        300: 'bg-indigo-300 hover:bg-indigo-400 disabled:bg-indigo-200 disabled:text-indigo-100',
        400: 'bg-indigo-400 hover:bg-indigo-500 disabled:bg-indigo-300 disabled:text-indigo-100',
        500: 'bg-indigo-500 hover:bg-indigo-600 disabled:bg-indigo-400 disabled:text-indigo-100',
        600: 'bg-indigo-600 hover:bg-indigo-700 disabled:bg-indigo-500 disabled:text-indigo-100',
        700: 'bg-indigo-700 hover:bg-indigo-800 disabled:bg-indigo-600 disabled:text-indigo-100',
        800: 'bg-indigo-800 hover:bg-indigo-900 disabled:bg-indigo-700 disabled:text-indigo-100',
        900: 'bg-indigo-900 hover:bg-indigo-800 disabled:bg-indigo-800 disabled:text-indigo-100',
    },
    yellow: {
        100: 'bg-yellow-100 hover:bg-yellow-200 disabled:bg-yellow-50 disabled:text-yellow-100',
        200: 'bg-yellow-200 hover:bg-yellow-300 disabled:bg-yellow-100 disabled:text-yellow-100',
        300: 'bg-yellow-300 hover:bg-yellow-400 disabled:bg-yellow-200 disabled:text-yellow-100',
        400: 'bg-yellow-400 hover:bg-yellow-500 disabled:bg-yellow-300 disabled:text-yellow-100',
        500: 'bg-yellow-500 hover:bg-yellow-600 disabled:bg-yellow-400 disabled:text-yellow-100',
        600: 'bg-yellow-600 hover:bg-yellow-700 disabled:bg-yellow-500 disabled:text-yellow-100',
        700: 'bg-yellow-700 hover:bg-yellow-800 disabled:bg-yellow-600 disabled:text-yellow-100',
        800: 'bg-yellow-800 hover:bg-yellow-900 disabled:bg-yellow-700 disabled:text-yellow-100',
        900: 'bg-yellow-900 hover:bg-yellow-800 disabled:bg-yellow-800 disabled:text-yellow-100',
    },
};

type Colors = 'gray' | 'blue' | 'red' | 'green' | 'orange' | 'indigo' | 'yellow';
type Tones = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

export { ColorsClasses }
export type { Colors, Tones }