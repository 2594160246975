import { Navigate, Outlet } from 'react-router-dom';
import { useAuthStore } from '../store/auth.store';

const PrivateGuard = () => {
    const auth = useAuthStore((state) => state.auth);

    if (!auth) {
        return <Navigate to="/login" />;
    }

    return <Outlet />;
};

export default PrivateGuard;
