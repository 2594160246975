import { AccessTokenPayload } from '../models/auth/access-token-payload.model';
import { AccessToken } from '../models/auth/access-token.model';
import { Session } from '../models/auth/session.model';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { create } from 'zustand';
import CryptoJS from 'crypto-js';

const ENCRYPTION_KEY = 'your-secret-key';

interface Auth {
    accessToken: AccessToken;
    payload: AccessTokenPayload;
    session: Session;
}

interface State {
    auth: Auth | undefined;
    setAuth: (accessToken: AccessToken, payload: AccessTokenPayload, session: Session) => void;
    getAuth: () => Auth | undefined;
    resetAuth: () => void;
}

const encryptedStorage = {
    getItem: (name: string) => {
        const encryptedData = localStorage.getItem(name);
        if (!encryptedData) return null;

        try {
            const bytes = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY);
            const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            
            return JSON.parse(decryptedData);
        } catch (error) {
            return null;
        }
    },
    setItem: (name: string, value: any) => {
        const jsonString = JSON.stringify(value);
        const encryptedData = CryptoJS.AES.encrypt(jsonString, ENCRYPTION_KEY).toString();
        localStorage.setItem(name, encryptedData);
    },
    removeItem: (name: string) => {
        localStorage.removeItem(name);
    },
};

const useAuthStore = create<State>()(
    devtools(
        persist(
            (set, get, api) => ({
                auth: undefined,
                setAuth: (accessToken, payload, session) => {
                    set({ auth: { accessToken, payload, session } });
                },
                getAuth: () => get().auth,
                resetAuth: () => {
                    set({ auth: undefined });
                    api.persist?.clearStorage();
                },
            }),
            {
                name: 'auth-store',
                storage: createJSONStorage(() => encryptedStorage),
            }
        )
    )
);

export { useAuthStore };
