import { faWhatsapp, faTelegramPlane } from '@fortawesome/free-brands-svg-icons';
import { JobMetrics } from '../../../../public/login/models/jobMetrics.model';
import { faThumbsUp, faEye } from '@fortawesome/free-solid-svg-icons';
import { useJobStore } from '../../../../../core/store/job.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Job } from '../../../../../core/models/jobs/job.model';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from '../../../../../core/components';
import { useNavigate, useParams } from 'react-router-dom';
import { JobService } from '../services/job.service';
import 'quill/dist/quill.bubble.css';
import './JobPreview.css';
import Swal from 'sweetalert2';
import $ from 'jquery';

const JobPreview: React.FC = () => {
    const [metrics, setMetrics] = useState<JobMetrics>(new JobMetrics());
    const [job, setJob] = useState<Job>(new Job());
    const { id } = useParams<{ id?: string }>();
    const stored = useJobStore.getState().getJob();

    const navigate = useNavigate();

    const GetJob = async (id: string) => {
        return await JobService.GetJob(id);
    };

    const GetMetrics = async (id: string) => {
        return await JobService.GetMetrics(id);
    };

    useEffect(() => {
        console.log("Ejecuta")

        $('a').each(function () {
            const title = $(this).attr('title');
            console.log(title)

            if (title) {
                $(this).attr('data-title', title);
                $(this).removeAttr('title'); 
            }

            $(this).on('mouseenter', function (e) {
                e.stopPropagation(); 
            });
        });

        $('a').each(function () {
            const dataTitle = $(this).attr('data-title');
            if (dataTitle) {
                $(this).attr('title', dataTitle);
            }
        });
    }, []);

    useEffect(() => {
        const Initialize = async () => {
            try {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });

                console.log(stored)

                if (!id && !stored) {
                    navigate("/private/job")

                    return;
                }

                if (id) {
                    let job = await GetJob(id);

                    setJob(job);

                    let metrics = await GetMetrics(id);

                    setMetrics(metrics);
                } else if (stored) {
                    const now = new Date();

                    setJob({
                        ...stored,
                        postedDate: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`
                    });

                    setMetrics({
                        LIKES: 0,
                        VIEWS: 0
                    })
                }

                const textareas = document.querySelectorAll("textarea");

                textareas.forEach((textarea) => {
                    const element = textarea;

                    element.style.height = element.scrollHeight + "px";
                    element.style.overflowY = "hidden";
                    element.style.maxHeight = element.scrollHeight + "px";
                    element.style.minHeight = element.scrollHeight + "px";

                    element.style.border = "none";
                    element.style.backgroundColor = "transparent";
                    element.style.resize = "none";
                    element.style.outline = "none";
                    element.style.color = "inherit";
                    element.style.font = "inherit";
                });

                console.log(job.description)
            } catch (error: any) {
                if (error.message.includes("SHOW: ")) {
                    navigate(`/job`);

                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        icon: 'info',
                        title: error.message.slice(5, error.message.length),
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer);
                            toast.addEventListener('mouseleave', Swal.resumeTimer);
                        }
                    });
                }
            }
        };

        Initialize();
    }, []);

    return (
        <Container>
            <div className='pb-20 pt-24 px-6 sm:pt-28 sm:px-14 xl:pt-16 xl:px-48 w-full text-start'>
                <div className='w-full bg-gray-50 h-12 fixed top-10 block md:hidden'></div>
                <p className='text-3xl md:text-6xl'>{job.name}</p>
                <div className='mt-10 space-y-4'>
                    <div className='flex'>
                        <span className='font-semibold w-32'>Company:</span>
                        <span>{job.company}</span>
                    </div>
                    <div className='flex'>
                        <span className='font-semibold w-32'>Location:</span>
                        <span>{job.location}</span>
                    </div>
                    <div className='flex'>
                        <span className='font-semibold w-32'>Category:</span>
                        <span>{job.category}</span>
                    </div>
                    <div className='flex'>
                        <span className='font-semibold w-32'>Subcategory:</span>
                        <span>{job.subCategory}</span>
                    </div>
                    <div className='flex'>
                        <span className='font-semibold w-32'>Posted on:</span>
                        <span>{job.postedDate}</span>
                    </div>
                </div>

                <p className='text-3xl md:text-xl mt-10 font-bold'>Job Description</p>

                <hr className="border-t border-gray-300 my-4" />

                <div className="ql-container ql-bubble">
                    <div
                        className="ql-editor"
                        style={{padding: '0!important'}}
                        dangerouslySetInnerHTML={{ __html: job.description }}
                    />
                </div>

                {
                    false && (
                        <>
                            <div className="mt-6 block xs:flex xs:justify-between xs:align-middle">
                                <div className='flex items-center align-middle xs:order-2'>
                                    <div>
                                        <button
                                            className="flex items-center space-x-2 bg-gray-700 text-white py-2 px-4 rounded-full transition-colors duration-300 focus:outline-none shadow-lg transform"
                                            id="btnView"
                                            disabled
                                        >
                                            <FontAwesomeIcon icon={faEye} className="text-white" size="lg" />
                                            <span className="font-semibold text-white">{metrics.VIEWS}</span>
                                        </button>
                                    </div>
                                    {<div className='ml-2'>
                                        <button
                                            className="flex items-center space-x-2 bg-blue-600 text-white py-2 px-4 rounded-full transition-colors duration-300"
                                            id="btnLike"
                                            disabled
                                        >
                                            <FontAwesomeIcon icon={faThumbsUp} className="text-white" size="lg" />
                                            <span className="font-semibold text-white">{metrics.LIKES}</span>
                                        </button>
                                    </div>}
                                </div>
                                <div className='flex mt-4 xs:order-1 xs:mt-0'>
                                    <div>
                                        <span className='font-semibold w-32'>Share on:</span>

                                        <div className='flex mt-2'>
                                            <button
                                                className="flex items-center justify-center w-14 h-14 bg-green-500 text-white rounded-full shadow-lg transform transition-transform duration-300 me-2"
                                                disabled
                                            >
                                                <FontAwesomeIcon icon={faWhatsapp} size="2x" />
                                            </button>

                                            <button
                                                className="flex items-center justify-center w-14 h-14 bg-blue-500 text-white rounded-full shadow-lg transform transition-transform duration-300"
                                                disabled
                                            >
                                                <FontAwesomeIcon icon={faTelegramPlane} size="2x" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-6 w-full flex align-middle justify-start'>
                                <button
                                    className="w-full md:w-fit bg-green-500 text-white font-semibold py-3 px-6 rounded-full shadow-lg transform transition-all duration-300 ease-in-out"
                                    disabled
                                >
                                    Apply now
                                </button>
                            </div>
                        </>
                    )
                }
            </div>
        </Container>
    );
};

export default JobPreview;