import { DeleteOutlined, EyeOutlined, PictureOutlined, PlusCircleOutlined, SolutionOutlined } from '@ant-design/icons';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Table, TableConfigure, Header, Container } from '../../../../../core/components';
import { IButton, ITableAction, ITableButton, ITableColumnType } from '../../../../../core/interfaces';
import { Job as ModelJob } from '../../../../../core/models/jobs/job.model';
import { UtilService } from '../../../../../core/services/util.service';
import { Collator } from '../../../../../core/constants';
import { JobService } from '../services/job.service';
import { useNavigate } from 'react-router-dom';

interface Props {
    action: boolean,
    ShowForm?: (item: ModelJob | null) => void,
}

const columns: ITableColumnType<ModelJob>[] = [
    {
        title: 'Job Name',
        dataIndex: 'name',
        key: 'name',
        filter: true,
        sorter: (a: ModelJob, b: ModelJob) => a.name.localeCompare(b.name),
    },
    {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        filter: true,
        sorter: (a: ModelJob, b: ModelJob) => Collator.compare(a.category, b.category)
    },
    {
        title: 'Posted On (YYYY-MM-DD)',
        dataIndex: 'createdDate',
        key: 'createdDate',
        filter: true,
        sorter: (a: ModelJob, b: ModelJob) => Collator.compare(a.subCategory, b.subCategory)
    },
    {
        title: '# of Applications',
        dataIndex: 'jobApplicationsSize',
        key: 'jobApplicationsSize',
        filter: true,
        sorter: (a: ModelJob, b: ModelJob) => a.jobApplicationsSize - b.jobApplicationsSize
    },
    {
        title: 'Draft',
        dataIndex: 'draft',
        key: 'Draft',
        filter: true,
        sorter: (a: ModelJob, b: ModelJob) => {
            if (a.draft === b.draft) return 0;
            return a.draft ? -1 : 1;
        },
        render: (draft) => (
            <span>
                {
                    (draft ? 'YES' : 'NO')
                }
            </span>
        )
    },
    {
        title: 'Published',
        dataIndex: 'published',
        key: 'published',
        filter: true,
        boolean: true,
        render: (published) => (
            <span>
                {
                    (published ? 'YES' : 'NO')
                }
            </span>
        ),
        sorter: (a: ModelJob, b: ModelJob) => {
            if (a.published === b.published) return 0;
            return a.published ? -1 : 1;
        }
    },
];

export interface JobPostTableRef {
    Get?: () => void;
}

const JobTable = forwardRef<JobPostTableRef, Props>(({ action, ShowForm }, ref) => {
    const [data, setData] = useState<ModelJob[]>([])
    const navigate = useNavigate()

    const Get = useCallback(async () => {
        const result = (await JobService.Get());

        setData(result);
    }, []);

    const Delete = async (item: ModelJob) => {
        await JobService.Delete(item.id);

        UtilService.Alert('Success!', 'success', "The Job has been deleted.", undefined, async () => {
            await Get();
        });
    }

    useEffect(() => {
        Get();
    }, [Get])

    useImperativeHandle(ref, () => ({
        Get: Get
    }), [Get]);

    const GetButtons = (): ITableButton<ModelJob>[] => {
        if (!action) {
            return [];
        }

        return [
            {
                title: 'Delete',
                icon: {
                    value: <DeleteOutlined />,
                    size: 'text-lg',
                },
                color: 'red',
                tone: 500,
                onClick: async (item) => await Delete(item),
            },
            {
                title: 'Applications',
                icon: {
                    value: <SolutionOutlined />,
                    size: 'text-lg',
                },
                color: 'orange',
                tone: 500,
                onClick: (item) => {
                    navigate(`/private/job/${item.id}/job-applications`)
                },
            },
            {
                title: 'Preview',
                icon: {
                    value: <PictureOutlined />,
                    size: 'text-lg',
                },
                color: 'green',
                tone: 500,
                onClick: (item) => {
                    window.open(`/#/private/job/${item.id}/preview`, '_blank');
                },
            },
        ];
    };

    const actions: ITableAction<ModelJob> = {
        visible: true,
        buttons: [
            ...(GetButtons()),
            {
                title: 'View',
                icon: {
                    value: <EyeOutlined />,
                    size: 'text-lg',
                },
                color: 'blue',
                tone: 500,
                onClick: async (item) => navigate(`/private/job/${item.id}`)
            }
        ],
        fixed: "none"
    };

    const { columns: enhancedColumns, data: enhancedData } = TableConfigure<ModelJob>({ columns, data, actions: actions });

    return (
        <Container>
            <Header
                title='Jobs'
                subtitle='Data List'
                buttons={[
                    {
                        text: {
                            value: "Add Job",
                            weight: 500,
                            size: "text-md",
                        },
                        icon: {
                            value: <PlusCircleOutlined />,
                            size: "text-lg",
                        },
                        color: "blue",
                        tone: 500,
                        btnClass: 'mr-1',
                        onClick: () => navigate("/private/job/create"),
                    }
                ]}
            />

            <div>
                <Table<ModelJob> columns={enhancedColumns} dataSource={enhancedData} rowKey="id" />
            </div>
        </Container>
    );
});

export default JobTable;