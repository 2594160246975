import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import React from 'react';
import PublicRoutes from './public/public.routes';
import PrivateRoutes from './private/private.routes';

const App: React.FC = () => {

  return (
    <Router>
      <Routes>
        <Route path="/*" element={<PublicRoutes />} />

        <Route path="/private/*" element={<PrivateRoutes />} />

        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </Router>
  );
};

export default App;
